
.findUs{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.menusidebar{
    flex: 1;
    min-height: 100vh !important;
}

.findUsFeed{
    flex: 4;
    max-height: 100vh;
    overflow-y: scroll;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
}

.findUsInfo{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 20px;
}

.findUsIcons{
    margin: auto;
}
.findUsInfoItem{
    margin-bottom: 20px;
}


@media all and (max-width: 700px){
    .topInformation{
        width: 90%;
        padding: 10px;
    }
}