.profileContainer{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.menusidebar{
    flex: 1;
    min-height: 100vh !important;
}


.updateProfileFeed{
    flex: 4;
    overflow-y: scroll;
    padding-left: 15px;
    padding-right: 15px;
}

.profileRequestContainer{
    padding-left: 30px;
    padding-right: 30px;
    border:  1px solid #C0C0C0;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 20px;
}

/* .goBackButton{
    background-color: #E56F09;
    border-radius: 18px;
    width: 100%;
    margin: auto;
    font-size: 10px;
    height: 30px;
    color: #1C1045;
    border: none;
    cursor: pointer;
} */

.editDetailsActivate{
    background-color: #E56F09;
    border-radius: 20px;
    width: 80%;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: center;
    font-size: 15px;
    height: 30px;
    font-weight: 500;
    color: #1C1045;
    border: none;
    cursor: pointer;
    text-align: center;
}

.editDetailsActivate:disabled{
    cursor: not-allowed;
    opacity: 0.4;
} 

.formDiv{
    margin: auto;
}

.successMessage{
    color: #E56F09;
    padding-top: 10px;
    text-align: center;
}

.errorMessage{
    color: red;
    /* font-size: 30px; */
    padding-top: 20px;
    text-align: center;
}

.imageCont{
    flex: 0.5;
    background-repeat: no-repeat;   
    background-size: cover;
    background-position:left;
}

.addressTextArea{
    padding-left: 25px;
    padding-top: 25px;
    padding-right: 15px;
    border-radius: 45px;
    width: 98%;
    border: solid 1px;
    background-color: #f5f5f5;
    resize: none;
    border: none;
    font-size: 20px;
    font-family: 'roboto';
}

.addressTextArea:focus{
    color:  #1C1045;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}

.mobilemenusidebar{
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 1;
    transition: 0.5s;
    width: 0px;
}

.mobileViewPageIdentifier{
    display: none;
    font-family: 'roboto';
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;

}
@media all and (max-width: 700px){

    .addressTextArea{
        font-size: 15px;
    }

    .menusidebar{
        display: none;
    }

    .profileRequestContainer{
        border: none;
        padding-left: 5px;
    }


    .mobileViewPageIdentifier{
        display: block;
    }


}
