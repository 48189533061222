.notificationsPage{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.notificationSidebar{
    flex: 1;
    min-height: 100vh !important;
}

.notificationsFeed{
    flex: 4;
    max-height: 100vh;
    font-family: 'roboto';
    padding-left: 15px;
    padding-right: 15px;
    overflow-y: scroll;
    
}

.notificationSubFeed{
    min-height: 100vh;
    overflow-y: scroll;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    max-height: 100vh;
    margin-bottom: 10px;
    padding-bottom: 5px;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}

.notificationSubFeed::-webkit-scrollbar {
    display: none;
}
.notificationsLoadingProgressDiv{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-family: 'roboto';
}
.notificationsDiv{
    display: flex;
    flex-wrap: wrap;
    background-color: #f8f8ff;
    cursor: pointer;
    margin-bottom: 5px;
    /* padding-bottom: 10px; */

}

.notificationsDiv:hover{
    background-color: #F5FFFA;
}

.notificationTitle{
    flex: 1.5;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 10px;
    color: #1C1045;
    border-right: 5px solid #fff;
    padding-bottom: 10px;
    
}


.notificationTitleText{
    font-size: 25px;
    margin: 0px;
    font-weight: 400;
    padding-bottom: 5px;
    color: #1C1045;
}

.notificationTitleDate{
    font-size: 15px;
    margin: 0px;
    padding-left: 3px;
    font-style: italic;
    font-weight: 300;
}

.notificationBody{
    flex: 4;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px;
    padding-top: 20px;
}


@media all and (max-width: 700px){
    

    .notificationsDiv{
        flex-direction: column;
    }

    .notificationBody{
        padding-top: 0px;
        padding-left: 20px;
    }

    .notificationTitle{
        border: none;
    }
}