/* splash screen */
.intro {
    position: fixed;
    background-color: rgb(255, 255, 255);
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}

.intro.display-none {
    position: fixed;
    background-color: rgb(70, 62, 62);
    opacity: 0;
    z-index: -10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transition: all 1s;
}

.start {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
}

@keyframes fadein {
    to{
        opacity: 1;
    }
}

#fade-in {
    opacity: 0;
    animation: fadeIn 3s ease-in forwards;
}