
.signup{
    display: flex;
}

.formContainer{
    flex: 2;
    height: 100vh;
    color: white;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 10px;
    background-color: #1C1045;
    overflow: auto;
    /* margin: 0; */
    /* top: 0;  */
}

.serverResponseMessages{
    width: 60%;
    text-align: center;
    margin: auto;
}

.errorMessage{
    color: red;
    font-size: 30px;
}

.imageCont{
    flex: 3;
    /* max-height: 100vh; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


/* Media Quries */

@media all and (max-width: 700px){
    .imageCont{
        display: none;
    }
}