@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700&family=Poppins:wght@500&family=Goldman:wght@700&family=DM+Sans:opsz,wght@9..40,400;9..40,700&family=Poppins:wght@400;500;600&display=swap");

.leftbarmenu
{
    height: 100%;
    overflow: auto;
    color: white;
    background-color: #1C1045;
    padding-top: 15px;
    top: 0; 
    margin: 0px;
}

#mobilemenusidebar{
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 1;
    transition: all 0.5s;
    width: 200px;
}

.closeMobileLeftBarIcon{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-right: 5px;
}

.react-responsive-modal-modal {
    min-width: 600px;
    border-radius: 15px;
}
.menuitems{
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.menuitems:hover{
    background-color:  #E56F09;  
    opacity: 0.8;
    padding-right: 50px;
}

.active{
    background-color:  #E56F09;  
    opacity: 0.8;
}

#logoutButton:hover{
    background-color:  #fff;  
    opacity: 1;
}


.logoutMessage{
    font-size: 30px;
    font-family: 'jost';
    font-weight: 500;
    margin-top: 30px;
    text-align: center;
    color: red;
}

.logoutCheckButtons{
    display: flex;
    justify-content: center;
}
.logoutCheckButton{
    width: 80px;
    height: 40px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    margin: 0 10px;
    padding: 5px;
}

@media all and (min-width: 1500px){
    .leftbarmenu{
        height: 100vh;
    }
    .intro{
        height: 100vh;
    }
    .react-responsive-modal-modal {
        width: 600px;   
    }
}

@media all and (max-width: 700px){
    .react-responsive-modal-modal {
        min-width: 300px;   
    }
    .intro{
        height: 100vh;
    }
}

@media all and (max-width: 400px){
    .react-responsive-modal-modal {
        width: auto; 
        overflow-x: scroll;  
    }
    .intro{
        height: 100vh;
    }
}
