
.activeorderspage{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.menusidebar{
    flex: 1;
    min-height: 100vh !important;
    flex-basis: 10%;
}

.activeOrdersFeed{
    flex: 4;
    max-height: 100vh;
    overflow-y: scroll;
    padding-left: 15px;
    padding-right: 15px;
}

.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;

}

.loadingProgressDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-family: 'roboto';
}

.tableLoadingProgressDiv{
    padding-right: 20px;
    position: fixed;
}

.renderInfo{
    border-radius: 20px;
    background-color: #1C1045;
    font-weight: 400;
    color: #fff;
    padding: 10px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.renderInfoButton{
    border-radius: 20px;
    background-color: #1C1045;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    width: 100px;
    height: 35px;
    text-align: center;
    cursor: pointer;
    border: none;
}

.printReceiptButton{
    border-radius: 20px;
    background-color: #1C1045;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    width: 100px;
    height: 35px;
    text-align: center;
    cursor: pointer;
    border: none;
}

.printReceiptButton:disabled{
    cursor:none;
    opacity: 0.4;
}

.orderModalHeader{
    font-size: 25px;
    font-weight: 700;
    color: #1C1045;
    margin: 0;
}

/* .orderModalLabel{
    display: inline;
    padding-top: 30px;
    font-size: 18px;
    font-weight: 600;
    color: #1C1045;
} */

.searchBar{
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-right: 5px;
}

.searchInput{
    font-size: 18px;
    font-family: 'roboto';
    padding-left: 5px;
    border: none;
    border-bottom: 2px solid black;
}

.searchInput:focus{
    color:  #1C1045;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}
.modalContent{
    font-family: 'roboto'
}

.orderStatusColorsDiv{
   width: 150px;
   text-align: center;
   border-radius: 15px;
   padding-top: 5px;
   padding-bottom: 5px;
   color: #fff;
}

#diagnosisSummaryDiv{
    padding-top: 20px;
}

.diagnosisSummaryTextItem{
    margin-top: 0px;
    word-wrap: break-word;
}

.viewDiagnosisButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 170px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.viewDiagnosisButton:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}

.viewInvoiceButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.viewInvoiceButton:disabled{
    cursor: not-allowed;
    opacity: 0.4;
}

.invoiceTable{
    padding: 20px 5px;
}

.invoiceActionButtons{
    background-color: #E56F09;
    border-radius: 20px;
    width: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
    margin-right: 10px;
}
.modalSuccessMessage{
    color: #E56F09;
    padding-top: 7x;
    text-align: center;
    font-size: 17px;
}

.modalFailureMessage{
    color: red;
    padding-top: 7px;
    margin: 0px;
    text-align: center;
    font-size: 17px;
}
.requestButtonDiv{
    padding: 15px 5px;
    padding-right: 30px;
    display: flex;
    flex-direction: row-reverse;
}

.modalBackButton{
    background: none;
    border: none;
    cursor: pointer;
}
@media all and (max-width: 700px){
    
    .activeOrdersFeed{
        padding-left: 10px;
        padding-right: 10px;
    }

    .requestButtonDiv{
        padding: 15px 5px;
        padding-right: 0px;
        
    }

    .loadingProgressDiv{
        position: absolute;
        left: 30%;
        top: 50%;
        transform: translate(-50%, -30%);
        padding: 10px;
    }
    
}

@media all and (min-width: 700px){
    .invoiceTable{
        min-width: 400px;
    }

}

@media all and (max-width: 700px){
    .tableLoadingProgressDiv{
        padding-right: 25px;
    }

    .invoiceActionButtons{
        width: 20px;
    }
}