
.request{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.menusidebar{
    flex: 1;
    min-height: 100vh !important;
}

.requestFeed{
    flex: 4;
    max-height: 100vh;
    overflow-y: scroll;
    padding-left: 15px;
    padding-right: 15px;

}

.requestContainer{
    padding-top: 10px;
    border:  1px solid #C0C0C0;
    border-radius: 0px 0px 8px 8px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
}

.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;

}
.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;
}

.formDiv{
    margin: auto;
}
.requestFormItems{
    /* width: 100; */
    margin-bottom: 30px;
    padding-left: 30px;;
    padding-right: 30px;
}
.formInput{
    width: 98%; 
    height: 20px;
    border-radius: 45px;
    border: solid 1px;
    padding-left: 20px;
    background-color: #f5f5f5;
    border: solid 1px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border: none;
    /* padding-right: 100px; */
    font-size: 20px;
    -webkit-appearance:none;
}


.formInput:focus{
    color:  #1C1045;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}

.deviceComplainTextArea{
    padding-left: 25px;
    padding-top: 25px;
    border-radius: 45px;
    width: 98%;
    border: solid 1px;
    background-color: #f5f5f5;
    resize: none;
    border: none;
    font-size: 20px;
    font-family: 'roboto';
}

.deviceComplainTextArea:focus{
    color:  #1C1045;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}
/* .goBackButton{
    background-color: #E56F09;
    border-radius: 18px;
    width: 100%;
    margin: auto;
    padding-top: 2px; 
    font-size: 15px;
    height: 30px;
    color: #1C1045;
    border: none;
    cursor: pointer;
} */

.buttonDiv{
    padding: 15px 5px;
    padding-right: 10px;
    display: flex;
    flex-direction: row-reverse;
}

.submitButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 150px;
    padding-top: 10px;
    padding-bottom: 9px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
}

.submitButton:disabled{
    opacity: 0.4;
    cursor: not-allowed;
}

@media all and (max-width: 700px){

    /* .requestFeed{
        padding-left: 10px;
        padding-right: 10px;
    } */
    
    .requestContainer{
        border: none;
        padding-left: 10px;
    }
   
    .formInput{
        width: 100%;
        font-size: 18px;
    }

    #deviceComplainTextArea{
        padding-right: 10px;
    }

    .deviceComplainTextArea{
        width: 100%;
        font-size: 15px;
    }

    .buttonDiv{
        padding-right: 0px;
    }

    .submitButton{
        font-size: 13px;
        width: 100px;
    }
}

/* #number-wrapper {
    position: relative;
}
  
#number-wrapper:after,
#number-wrapper:before {
    position: absolute;
    right: 5px;
    width: 1.6em;
    height: .9em;
    font-size: 10px;
    pointer-events: none;
    background: #fff;
}
  
#number-wrapper:after {
    color: blue;
    content: "\25B2";
    margin-top: 1px;
}
  
#number-wrapper:before {
    color: red;
    content: "\25BC";
    margin-bottom: 5px;
    bottom: -.5em;
} */


input[type=number] {
    /*for absolutely positioning spinners*/
    position: relative; 
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}

input[type=number]::-webkit-outer-spin-button, 
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button !important;
    width: 10px;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 70%;
    margin-right: 10px;
    padding-right: 10px;
}