
.orderHistoryPage{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.menusidebar{
    flex: 0.8;
    min-height: 100vh !important;
}

.orderHistoryFeed{
    flex: 3;
    max-height: 100vh;
    padding-top: 0px;
    padding: 15px;
    overflow-y: scroll;
}

.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;

}

.loadingProgressDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-family: 'roboto';
}

.renderInfo{
    border-radius: 20px;
    background-color: #1C1045;
    font-weight: 400;
    color: #fff;
    padding: 10px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.renderInfoButton{
    border-radius: 20px;
    background-color: #1C1045;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    width: 100px;
    height: 35px;
    text-align: center;
    cursor: pointer;
    border: none;
}

.orderModalHeader{
    font-size: 25px;
    font-weight: 700;
    color: #1C1045;
    margin: 0;
}

.modalContent{
    font-family: 'roboto'
}

.viewDiagnosisButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 170px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.viewInvoiceButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 220px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;

}

.invoiceActionButtons{
    background-color: #E56F09;
    border-radius: 20px;
    width: 130px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
    margin-right: 10px;
}

.requestButtonDiv{
    padding: 15px 5px;
    padding-right: 30px;
    display: flex;
    flex-direction: row-reverse;
}


.orderCountDiv{
    padding: 15px 5px;
    padding-right: 30px;
    display: flex;
    font-family: 'roboto';
    flex-direction: row-reverse;
}