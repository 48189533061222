
.feedBackPage{
    display: flex;
    overflow-y: hidden;
    max-height: 100vh;
}

.menusidebar{
    flex: 1;
    min-height: 100vh !important;
}

.feedBackFeed{
    flex: 4;
    max-height: 100vh;
    overflow-y: scroll;
    padding-left: 15px;
    padding-right: 15px;
}

.feedBackMContainer{
    padding-top: 10px;
    border:  1px solid #C0C0C0;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 20px;
}

.formDiv{
    padding-top: 20px;
    padding-right: 20px;
}

.formSelect{
    width: 100%;
    height: 70px;
    border-radius: 45px;
    border: solid 1px;
    background-color: #f5f5f5;
    border: solid 1px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    border: none;
    padding-right: 25px;
    font-size: 15px;
    -webkit-appearance:none;
}

.formTextArea{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    border-radius: 45px;
    width: 98%;
    border: solid 1px;
    background-color: #f5f5f5;
    resize: none;
    border: none;
    font-size: 20px;
    font-family: 'roboto';

}

.topInformation{
    text-align: center;
    margin: auto;
    color: #1C1045;
    padding-top: 20px;
    font-size: 25px;
    font-weight: 300;
    font-family: 'roboto';

}

.formTextArea:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}


.baseSendButtonDiv{
    padding: 15px 5px;
    padding-right: 30px;
    display: flex;
    flex-direction: row-reverse;
}

.baseSendButton{
    background-color: #E56F09;
    border-radius: 20px;
    width: 120px;
    padding-top: 10px;
    padding-bottom: 9px;
    font-weight: 550;
    color: #1C1045;
    border: none;
    cursor: pointer;
}
.baseButtonItems{
    display: flex;
    justify-content: center;
    font-size: 18px;

}

.baseButtonIcon{
    padding-left: 10px;
    padding-top: -5px;
}

.errorMessage{
    color: red;
    font-size: 30px;
    text-align: center;

}
@media all and (max-width: 700px){
    .topInformation{
        font-size: 15px;
        text-align: left;
    }

    .feedBackMContainer{
        border: none;
    }

    .baseSendButton{
        width: 100px;
    }
    .baseButtonItems{
        font-size: 15px;
    }
}